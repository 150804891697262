module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BTK2G4DK7K"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Should I Watch This Anime?","short_name":"SIWTA","description":"Your one stop answer to whether you should watch a particular anime.","lang":"en","start_url":"/","background_color":"#d9f99d","theme_color":"#404040","display":"standalone","icon":"src/images/icon.png","crossOrigin":"anonymous","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"150fdbbf0bc2f533905048631e9b47e4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
